<ion-header [translucent]="true">
	<ion-toolbar [color]="isDarkMode ? undefined : 'primary'">
		<!-- <ion-buttons slot="start">
			<ion-button icon-only fill="clear" (click)="openAboutUs()">
				<ion-icon name="information-circle-outline"></ion-icon>
			</ion-button>
		</ion-buttons> -->
		<ion-title [ngStyle]="isDarkMode ? null : { color: 'var(--ion-color-primary-contrast)' }" [color]="isDarkMode ? 'primary' : undefined">
			PonyPlace
		</ion-title>
		<ion-buttons slot="end">
			<ion-button fill="clear" (click)="skipTutorial()">Skip</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
	<ion-slides pager="true">
		<ion-slide *ngFor="let slide of slideData; let i = index">
			<h2>{{ slide.title }}</h2>
			<!-- use slide.descrpition as HTML -->
			<p class="slide-desc" [innerHTML]="slide.description"></p>
			<section *ngIf="i===2">
				<ion-button [disabled]="isSliding" fill="outline" (click)="requestPush()">Enable Push Notifications</ion-button>
			</section>

			<ion-button
				style="margin-top: 1rem"
				[fill]="i === slideData.length - 1 ? 'clear' : (isDarkMode ? 'clear' : 'solid')"
				color="primary"
				(click)="goToSlide($event, i + 1)"
				[disabled]="isSliding"
			>
				{{ slide.buttonText }}
			</ion-button>
		</ion-slide>
	</ion-slides>
</ion-content>
