/* eslint-disable @typescript-eslint/naming-convention */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: true,
	app_name: 'PonyPlace',
	version: '1.0.1',
	api_version: '1.1',
	dev_mode: false,
	web_url: 'https://beta.ponyplace.net',
	base_api_url: 'https://api.ponyplace.net/api',
	websocket_url: 'wss://api.ponyplace.net/api/ws/chat/',
	websocket_url2: 'wss://api.ponyplace.net/api/ws/chat2/',
	websocket_local: 'ws://localhost:4000',
	chat_version: 2,
	algolia: {
		app_id: 'XF7FGEYURL',
		search_api_key: 'e6c8ae9cdd51a8ce2e10c316b1e608c0',
		indexes: {
			horse: 'prod_horses',
			tack: 'prod_tack',
		},
	},
	one_signal: {
		app_id: '7a90fcdb-725e-49dc-83e5-e8f6e02d1625',
		google_project_id: '153016708540',
	},
	store_download: {
		ios: 'https://itunes.apple.com/app/id1248092739',
		android: 'https://play.google.com/store/apps/details?id=com.ponyplace.io',
	},
	base_api_url2: 'https://legacyapi.ponyplace.net/v1',
	support_api_url: 'https://support.ponyplace.net/api',
	support_api_url_ext: '/http.php/tickets',
	media_url: 'https://legacyapi.ponyplace.net/media',
	image_upload: '/upload/image/',
	app_version_url: 'https://legacyapi.ponyplace.net/v1/current_version/current_version.php',
	websocket_url_test: 'ws://echo.websocket.org/',
	websocket_url_local: 'ws://localhost:8000/api/ws/chat/',
	google: {
		analytics: 'G-PWHK5GJZBV',
	},
	clarifai: {
		api_key: 'bbae2707190e4368a824873a7fd936f8',
	},
	logo_icon: 'assets/images/logo.png',
	control_limits: {
		photoLimit: 15,
		videoLimit: 5,
		renewWindowLimit: 5,
	},
	mapbox_key: 'pk.eyJ1Ijoic2FsY290dCIsImEiOiJjazF4dmQ5MzUwZGpiM21vM2VpNzN2NWE3In0.nRVw74BEnIoXiaPvxz139w',
	mapbox_url: 'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=',
	algolia_places_id: 'plFT8Y28WVCO',
	algolia_places_key: '962e44bf921c168c967f45c2fcf51779',
	image_base_path: 'https://images.ponyplace.net',
	upload_api_url: 'https://images.ponyplace.net/api/upload/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
