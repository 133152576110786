import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, ModalController } from '@ionic/angular';
import { PushService } from '../../providers/push/push.service';
import { UtilService } from '../../providers/util/util.service';

@Component({
	selector: 'app-tutorial',
	templateUrl: './tutorial.page.html',
	styleUrls: ['./tutorial.page.scss'],
})
export class TutorialPage implements OnInit, AfterViewInit {
	@ViewChild(IonSlides) slides: IonSlides;

	public slideData = [
		{
			title: 'Welcome to PonyPlace',
			description: 'the simplest horse marketplace.',
			buttonText: 'Continue',
		},
		{
			title: "Buyer's Choice",
			description: 'filters to find the perfect pony.',
			buttonText: 'Continue',
		},
		{
			title: "Seller's Paradise",
			description: 'chat with buyers in realtime.',
			buttonText: "Let's go!",
		},
	];

	isDarkMode = this.utilService.isDarkMode;
	isSliding = false;

	constructor(
		private modalController: ModalController,
		private pushService: PushService,
		private utilService: UtilService
	) {}

	ngAfterViewInit() {
		// Listen to slide change events
		this.slides.ionSlideWillChange.subscribe(() => {
			this.isSliding = true;
		});
		this.slides.ionSlideDidChange.subscribe(() => {
			this.isSliding = false;
		});
	}

	ngOnInit() {
		console.log('tutorial init');
		console.log('user permission statuses?');
		const pushState = this.pushService.getPushState();
		console.log('pushState:');
		console.log(pushState);
	}

	openAboutUs() {
		console.log('openAboutUs()');
	}

	requestPush() {
		this.pushService.requestPushPermission();
	}

	goToSlide(event, index: number) {
		event.stopPropagation();
		if (this.isSliding) {
			console.log('Slide transition in progress, blocking click.');
			return;
		}
		this.slides.slideTo(index);
		if (index === this.slideData.length) {
			this.modalController.dismiss();
		}
	}

	skipTutorial() {
		console.log('skipTutorial()');
		this.modalController.dismiss();
	}
}
