import { Injectable } from '@angular/core';
import { AlertService } from '../alert/alert.service';
import { NavigationExtras, Router } from '@angular/router';
import confetti from 'canvas-confetti';

@Injectable({
	providedIn: 'root',
})
export class UtilService {
	constructor(
		private alertService: AlertService,
		private router: Router
	) {}

	public triggerConfetti() {
		confetti({
			particleCount: 200,
			spread: 70,
			origin: { y: 0.6 },
		});
	}

	public isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

	public formatDate(dateString: string, options: Intl.DateTimeFormatOptions = {}, locale: string = 'en-US'): string {
		if (!dateString) return '';

		// Ensure the date is correctly parsed by handling timezone offsets manually
		const dateObj = new Date(dateString.replace(/(\d{2})(\d{2})$/, '$1:$2'));

		// Default options for a friendly display
		const defaultOptions: Intl.DateTimeFormatOptions = {
			weekday: 'short', // "Mon"
			year: 'numeric', // "2024"
			month: 'short', // "Oct"
			day: 'numeric', // "21"
			hour: 'numeric', // "5"
			minute: '2-digit', // "56"
			second: '2-digit', // "53"
			hour12: true, // "PM/AM format"
			timeZoneName: 'short', // "CDT"
		};

		// Merge default options with provided options
		const finalOptions = { ...defaultOptions, ...options };

		// Format the date
		return dateObj.toLocaleString(locale, finalOptions);
	}

	public formatMoney(num, places, symbol, thousand = null, decimal = null) {
		num = num || 0;
		places = !isNaN((places = Math.abs(places))) ? places : 2;
		symbol = symbol !== undefined ? symbol : '$';
		thousand = thousand || ',';
		decimal = decimal || '.';
		const negative = num < 0 ? '-' : '';
		const i: any = parseInt((num = Math.abs(+num || 0).toFixed(places)), 10) + '';
		let j: number;
		j = (j = i.length) > 3 ? j % 3 : 0;
		// eslint-disable-next-line max-len
		const output =
			symbol +
			negative +
			(j ? i.substr(0, j) + thousand : '') +
			i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousand) +
			(places
				? decimal +
					Math.abs(num - i)
						.toFixed(places)
						.slice(2)
				: '');
		return output;
	}

	public tokenExpired(token: string) {
		const expiry = JSON.parse(atob(token.split('.')[1])).exp;
		return Math.floor(new Date().getTime() / 1000) >= expiry;
	}

	public isValidEmail(email: string) {
		const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
		if ((email !== '' && (email.length <= 5 || !EMAIL_REGEXP.test(email))) || !email) {
			return false;
		}
		return true;
	}

	public isEndOfResults(count: number, items: any[]): boolean {
		if (count && items && items.length && count === items.length) {
			return true;
		} else if (count > items.length) {
			return false;
		}
	}

	public distance(lat1?: number, lon1?: number, lat2?: number, lon2?: number): number | boolean {
		if (typeof lat1 != undefined && typeof lon1 != undefined && typeof lat2 != undefined && typeof lon2 != undefined) {
			const p = 0.017453292519943295; // Math.PI / 180
			const c = Math.cos;
			const a = 0.5 - c((lat2 - lat1) * p) / 2 + (c(lat1 * p) * c(lat2 * p) * (1 - c((lon2 - lon1) * p))) / 2;
			return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
		}
		return false;
	}

	public roundWhole(val?: number) {
		if (typeof val != undefined) {
			return Math.round(val) + ' mi';
		}
		return false;
	}

	public formatPhoneToUs(phone?: string) {
		if (!phone || phone == null) {
			return '';
		}
		let output = phone.replace(/[^0-9]*/g, '');
		if (output.charAt(0) === '1') {
			output = output.substr(1);
			console.log('formatted phone subscr');
			console.log('output', output);
		}
		output = this.filterPhone(output);
		return output;
	}

	public filterPhone(val: string) {
		if (!val) {
			return '';
		}
		val = String(val);
		val = val.replace(/[^0-9]*/g, '');
		let formattedNumber = val;
		const c = val[0] === '1' ? '1' : '';
		val = val[0] === '1' ? val.slice(1) : val;
		const area = val.substring(0, 3);
		const front = val.substring(3, 6);
		const end = val.substring(6, 10);
		if (front) {
			formattedNumber = c + '(' + area + ') ' + front;
		}
		if (end) {
			formattedNumber += '-' + end;
		}
		return formattedNumber;
	}

	convertKeys(obj: any): any {
		if (obj === null || typeof obj !== 'object') {
			return obj;
		}

		if (Array.isArray(obj)) {
			return obj.map((item) => this.convertKeys(item));
		}

		return Object.keys(obj).reduce((acc, key) => {
			const snakeCaseKey = this.camelToSnakeCase(key);
			acc[snakeCaseKey] = this.convertKeys(obj[key]);
			return acc;
		}, {});
	}

	private camelToSnakeCase(camelCase: string): string {
		return camelCase.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase();
	}
}
