import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { NavigationEnd, Router } from '@angular/router';
import { StorageService } from './providers/storage/storage.service';
import { LoaderService } from './providers/loader/loader.service';
import { User, UserService } from './providers/user/user.service';
import OneSignal from 'onesignal-cordova-plugin';
import { PushService } from './providers/push/push.service';
import * as fromApp from './app.reducer';
import { select, Store } from '@ngrx/store';
import { getUser } from './providers/user/store/user.selectors';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
	private user: User;
	private _appInit = false;

	constructor(
		private platform: Platform,
		private storageService: StorageService,
		private router: Router,
		private loaderService: LoaderService,
		private userService: UserService,
		private pushService: PushService,
		private store: Store<fromApp.AppState>
	) {
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				console.log('Navigated to:', event.urlAfterRedirects);

				// @TODO:
				// implement app wide seo / title meta tags
				// perhaps mapped with a definition for each page that I want to inform
			}
		});
	}

	ngOnInit() {
		this.platform.ready().then(() => this.startApp());
	}

	startApp() {
		this.store.pipe(select(getUser)).subscribe((user: User) => {
			if (!this.user && user) {
				console.log('USER SET IN APP');
				console.log(user);
			}
			this.user = user;
		});

		if (this.router.url === '/' || this.router.url === '/index.html') {
			console.log('Navigating to /tabs');
			this.router.navigate(['/tabs']);
		}
		// console.log("setting OneSignal.Notifications.addEventListener('foregroundWillDisplay')");
		// OneSignal.Notifications.addEventListener('foregroundWillDisplay', this.onNotificationReceived.bind(this));

		if (!this._appInit) {
			this._appInit = true;
			console.log('_appInit: true, pushService should start...');
		}

		// const state = await OneSignal.getPermissionSubscriptionState();
		// console.log('Device state after cold start:', state);

		// // Handle notification opens
		// OneSignal.setNotificationOpenedHandler((jsonData) => {
		// 	console.log('Notification opened:', jsonData);
		// 	// Save to storage or send to backend
		// });
	}

	// async onNotificationReceived(event: any) {
	// 	console.log('onNotificationReceived() - foregroundWillDisplay - ');
	// 	console.warn('SETTING lastNotification');
	// 	await this.storageService.setItem('lastNotification', event.notification);
	// 	if (typeof event.completed !== 'undefined') {
	// 		event.complete();
	// 	}
	// }
}
