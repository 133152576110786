<ion-header collapse="condense" [translucent]="true">
	<ion-toolbar>
		<ion-title>Page Not Found</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content class="ion-padding" [fullscreen]="true">
	<h2>Oops! This page doesn't exist.</h2>
	<p>The page you are looking for cannot be found.</p>
	<ion-button routerLink="/">Go Home</ion-button>
</ion-content>
