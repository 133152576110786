import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginPage } from './pages/login/login.page';
import { NotFoundPage } from './pages/not-found/not-found.page';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
	},
	{
		path: 'login',
		loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
	},
	{
		path: 'chat/:thread_pk',
		loadChildren: () => import('./pages/chat/chat.module').then((m) => m.ChatPageModule),
		runGuardsAndResolvers: 'always',
	},
	{
		path: 'user/:user_pk',
		loadChildren: () => import('./pages/tabs/account/account.module').then((m) => m.AccountPageModule),
		pathMatch: 'full',
	},
	// All routes similar to the one below should come before it, so that the router doesn't mistake the slug for a category
	// {
	// 	path: ':category/:pk',
	// 	loadChildren: () => import('./pages/listing/listing.module').then((m) => m.ListingPageModule),
	// 	runGuardsAndResolvers: 'always',
	// },
	// {
	// 	path: 'horse/:pk',
	// 	loadChildren: () => import('./pages/listing/listing.module').then((m) => m.ListingPageModule),
	// 	runGuardsAndResolvers: 'always',
	// },

	{
		path: ':category/:pk',
		loadChildren: () => import('./pages/listing/listing.module').then((m) => m.ListingPageModule),
		// runGuardsAndResolvers: 'always',
		pathMatch: 'full',
		data: { reuse: false },
		// matcher: (url) => {
		// 	console.log('URL segments:', url);
		// 	if (url.length === 2) {
		// 		const [category, pk] = url;
		// 		const uuidRegex = /^[0-9a-fA-F-]{36}$/;
		// 		if (uuidRegex.test(pk.path)) {
		// 			console.log('Matched category:', category.path, 'PK:', pk.path);
		// 			return { consumed: url, posParams: { category, pk } };
		// 		}
		// 	}
		// 	return null;
		// },

		// matcher: (url) => {
		// 	if (url.length === 2) {
		// 		const [category, pk] = url;
		// 		const uuidRegex = /^[0-9a-fA-F-]{36}$/;
		// 		if (uuidRegex.test(pk.path)) {
		// 			return { consumed: url, posParams: { category: category, pk: pk } };
		// 		}
		// 	}
		// 	return null;
		// },
	},
	{
		path: 'new-chat',
		loadChildren: () => import('./pages/new-chat/new-chat.module').then((m) => m.NewChatPageModule),
	},
	{
		path: 'edit-listing',
		loadChildren: () => import('./pages/edit-listing/edit-listing.module').then((m) => m.EditListingPageModule),
	},
	{
		path: 'privacy',
		loadChildren: () => import('./pages/tabs/settings/privacy/privacy.module').then((m) => m.PrivacyPageModule),
	},
	{
		path: 'blocked-users',
		loadChildren: () => import('./pages/tabs/chat/blocked-users/blocked-users.module').then((m) => m.BlockedUsersPageModule),
	},
	{
		path: 'archived-chats',
		loadChildren: () => import('./pages/tabs/chat/archived-chats/archived-chats.module').then((m) => m.ArchivedChatsPageModule),
	},
	{
		path: 'new-message',
		loadChildren: () => import('./pages/new-message/new-message.module').then((m) => m.NewMessagePageModule),
	},
	{
		path: 'tutorial',
		loadChildren: () => import('./pages/tutorial/tutorial.module').then((m) => m.TutorialPageModule),
	},
	{
		path: 'map',
		loadChildren: () => import('./pages/map/map.module').then((m) => m.MapPageModule),
	},
	{
		path: 'tabs',
		loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
	},

	{
		path: '**',
		component: NotFoundPage,
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
			onSameUrlNavigation: 'reload',
			// Enable location strategy for direct URL access
			useHash: false,
			// Important for SEO and social sharing
			initialNavigation: 'enabledBlocking',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
